.o-casestudy {
	h1 {
		font-weight: $bold;
	}
	.accordion {
		margin: 25px 0;
	
		&-box {
			padding: 30px 0;
			border-bottom: 1px solid #dedede;
			float: left;
			width: 100%;


			&:last-child {
				border-bottom: none;
			}
			
			&.add {
			}

			.top {
				width: 100%;
				float: left;

				.col-md-10 {
					margin-left: -15px;
				}

				&.hidecontent {
					p {
						display: none;
					}
				}

				.accordionbtn {
					width: 160px;
					text-align: center;
					float: right;
				}
			}

			h3 {
				font-weight: $semibold;
				font-size: 28px;
			}
		}

		&-content {
			width: 100%;
			float: left;

			&-inn {

				h3 {
					font-size: 28px;
					font-weight: $semibold;
				}

				&-left {
					

					@include rwd(978) {
						margin: 0 -15px;
					}
					

					h4 {
						text-transform: uppercase;
						font-weight: $semibold;

						&:after {
						    bottom: 16px;
						    margin-left: 2px;
						}
					}

					
				}

				.bg-gray {
					background-color: $semi-gray-light;
					border-top:10px solid $yellow-dark;
					padding:20px;
					
					@include rwd(978) {
						min-height: 430px;
					}

					h4 {
						color: $white !important;

						&:after {
						    bottom: 16px;
						    margin-left: 2px;
						}
					}

					p {
						color: $white;
					}
				}


				&-right {
					@include xxl {
						margin-right: -15px;
					}
					h3 {
						font-size: 28px;
						font-weight: $semibold;
					}

					h4 {
						margin:10px 0;
						&:after {
							display: none;
						}
					}

					.contentbox {
						background-color:#f3eaf3;
						padding:20px;
						margin-right: -15px;
						margin-bottom: 20px;

						p {
							margin-bottom: 0px;
						}

						img {
							width: auto;
						}
					}

					img {
						max-width: 100%;
						width:100%;
					}
				}
			}

			&-row {
				clear: both;
				display: block;
				width: 100%;
				padding:30px 0;
				border-bottom:1px solid #e3e3e3;
				float: left;

				&:last-child {
					border-bottom:none;
				}
			}


		}


				 
		h4 {
			display: block;
			margin: 0;
			color: $purple;
			padding: 0px 0 10px 0;
			font-weight: 600;
				a {
					display: block;
					text-decoration: none;
					color: $purple;
				}
			}

	}
}

