*, html, body {
  padding: 0;
  margin: 0;
  outline: none;
  font-family: 'Montserrat', sans-serif;
   font-weight: normal;
    font-style: normal;
  color:$gray;
}
* {
  &:before, &:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
}


::selection,
::-moz-selection{
    background: #C43131;
  color: #fff;
  text-shadow: none;
}


html, body {
    width:100%;
    height:100%;
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%;
  }
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  padding: 0;
  margin: 0;
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
  }
body {
    margin: 0;
    height:auto; 
    min-height:100%; 
    overflow-x:hidden; 
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: $base-font-size; 
    font-weight: $light; 
    line-height: 24px; 
    color: $gray;
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 
    background-color: #fff;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
  }


img {
  border: 0;
  max-width: 100%; 
}
svg:not(:root) {
  overflow: hidden;
}


a {
    //@include prefixed-transition(.5s, ease-in); 
    text-decoration: none; 
    color: $gray; 
    
    &:hover {
        color: $gray;
        text-decoration: none;
    }

    &:focus {
       color:$gray;
       text-decoration: none;
    }
}


.alignleft {
  float: left;
  margin: 0;

  /* @include responsive(res768) {
    margin: 0 30px 20px 0;
  }
  @include responsive(res992) {
    margin: 0 40px 20px 0;
  } */
}
.alignright {
  float: right;
  margin: 0;

  /* @include responsive(res768) {
    margin: 0 0 30px 30px;
  }
  @include responsive(res992) {
    margin: 0 0 40px 30px;
  } */
}
.alignnone {
  float: none;
  margin: 0;

  /* @include responsive(res768) {
    margin: 0 0 20px;
  } */
}


figure, ul {
    margin: 0;
    padding: 0;
}


a, button {
  transition: all ease-in-out 200ms;
}
  
a {
    color: #3b3b3b;
    &:focus {
      outline: none;
    }
  }
  
li {
  list-style: none;
  letter-spacing: -0.5px;
}

img {
  max-width: 100%;
}

p {
  font-size: 15px;
  line-height: 25px;
  color: $dark-gray;
  margin: 0 0 20px 0;
  font-weight: 400;
  letter-spacing: -0.5px;
  }
    
h1, .h1{
  font-size: 48px;
  line-height: 60px;
 font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  letter-spacing: -0.5px;
  
}

h2, .h2 {
  font-size: 30px;
  line-height: 40px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.5px;

  @include xl {
    font-size: 38px;
    line-height: 48px;
  }
  
}

h3, .h3 {
  font-size: 22px;
  line-height: 30px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.5px;

  @include xl {
    font-size: 32px;
    line-height: 40px;
  }
  
}
    
h4, .h4 {
  font-size: 14px;
  line-height: 25px;
  font-family: 'Montserrat', sans-serif;
  position: relative;

  &:after {
    position:absolute;
    content: '';
    bottom: 7px;
    width: 5px;
    height: 5px;
    border-radius:100px;
    background-color:$yellow-gold;
    margin-left: 3px;
  }
}

h5, .h5 {
  font-size: 30px;
  line-height: 35px;
  font-family: 'Montserrat', sans-serif;
}
  
h6, .h6 {
  font-size: 24px;
  line-height: 30px;
  font-family: 'Montserrat', sans-serif;

}


// Animation
@keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
} 