.btn {
	font-size: 16px;
	font-weight: $bold;
	line-height: 24px;
	padding:15px 25px;
	border: none;
	border-radius: 0;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s; /* For Safari 3.0 to 6.0 */
	transition: all 0.3s; /* For modern browsers */
	position: relative;

	&:hover {
		text-decoration: none;
	}
	&:focus {
		text-decoration: none;
		outline: none;
		border:none;
	}

	&-yellow {
		background: $yellow-gold;

		&-border {
			border:1px solid $yellow-dark;
		}

		&:after {
			position:absolute;
			content: '';
			top: 52%;
			right: auto;
			width: 5px;
			height: 5px;
			border-radius:100px;
			background-color:$purple;
			margin-left: 5px;
		}

		&:hover {
			background-color:$yellow-dark;
		}

	}
}

.readmore {
	font-size: 12px;
	color:$white;
	padding:5px 10px 5px 5px;
	background-color:$purple;
	position:relative;

	&:after {
		position:absolute;
		content: '';
		top: 52%;
		right: 8%;
		width: 5px;
		height: 5px;
		border-radius:100px;
		background-color:$yellow-gold;
	}

	&:hover {
		color:$yellow-dark;
	}
}