.header {
	width: 99%;
	position: relative;
	top: 0;
	margin:0 auto;
	background-color: $white;
	z-index: 1;
	padding:10px 0;
	transition: all 0.5s ease 0.1s;

	@include lg {
		position: fixed;
	}

	.logo {
		margin-top: 0px;
		transition: all 0.5s ease 0.1s;
		text-align: center;

		@include lg {
			text-align: left;
		}

		.img-logo {
			display: none;
			transition: all 0.5s ease 0.1s;
		}

		.txt-logo {
			display: inline-block;
			transition: none !important;
			font-family: 'Roboto', sans-serif;
			font-size: 37px;
			font-weight:900;
			line-height: .8;
			padding-top: 15px;
			padding-left:0px;
			color: $gray-mid;
			position:relative;

			@include rwd(1100) {
				transition: all 0.5s ease 0.1s;
			}


			&:after {
				content: "";
				top: 79%;
				right: -9px;
				width:7px;
				height:7px;
				border-radius: 100px;
				background-color:$purple;
				position: absolute;

			}
		}
	}

	.dextopmenu {
		display: none;

		@include lg {
			display: block;
		}	
	}

	.mobilemenu {
		display: block;

		&-anchore {
			font-size: 30px;
			margin-left: 20px;
			float: left;
			position: relative;
			z-index: 1;

			@include lg {
				display: none;
			}

			i {
				color: $yellow-dark
			}
		}

		@include lg {
			display: none;
		}
	}

	.navigation {
		width: 100%;
		display: block;
		ul {
			li {
				display: inline-block;
				padding: 0 15px;
				position: relative;

				&:last-child {
					padding: 0 0 0 15px;
				}

				&.search {
					&:before {
						position:absolute;
						content: '';
						background-image: url('../../images/search.png');
						width: 13px;
						height: 14px;
						background-repeat: no-repeat;
						background-position:50% 50%;
						background-size:80%;
						top: 6px;
						left: 0;
					}
				}
				a {
					font-size: 12px;
					line-height: 18px;
					text-transform: uppercase;
					color:$gray-mid;
					font-weight: $semibold;

					&:hover {
						color:$yellow-gold;
					}
				}
			}
		}
	}

	.sub-navigation {
		width: 100%;
		display: block;
		padding:15px 0 0 0;
		transition: all 0.5s ease 0.1s;

		@include rwd(1400) {
				max-width: 1100px;
				float: right;
			}

		ul {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			width: 100%;
			
			li {
				width: auto;
				padding: 0 15px;
				text-align: left;

				&:last-child {
					padding: 0 0 0 15px;
				}
				a {
					font-size: 14px;
					line-height: 20px;
					text-transform: normal;
					color:$purple;
					font-weight: $semibold;

					&:hover {
						color:$yellow-gold;
					}
				}

				p {
					font-size:13px;
					color: $gray-mid-light;
					display: none;
					transition: all 0.5s ease 0.1s;
				}
			}
		}
	}
}
.expandMe {
	transition: all 0.5s ease 0.1s;
}

.expanded {
	padding: 20px 0;

	@include lg {
		padding:40p 0;
	}

	.sub-navigation {
		padding: 50px 0 0 0;

		ul {
			li {
				width: 25%;
				p {
					display: block;
				}
			}
		}
	}

	.logo {
		margin-top: 0px;
		text-align: left;

		@include lg {
			margin-top: 40px;
			text-align: left;
		}

		.img-logo {
			display: none;

			@include lg {
				display: block;
			}
		}
		.txt-logo {
			display: inline-block;
			text-align: left;
    		padding-top: 2px;
    		padding-left: 20px;

			@include lg {
				display: none;
				padding:0px;
			}
		}
	}
}

.mobilemenu {
	display: block;
	@include lg {
		display: none;
	}
}