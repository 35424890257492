.footer {
	clear: both;
	&-social {
		h4 {
			color:$yellow-gold;
			text-transform: uppercase;
			font-size: 12px;
		}
		ul {
			li {
				display: inline-block;
				width: 25px;
				vertical-align: middle;
				margin-right: 15px;
				text-align: center;
			}
		}
	}

	&-top {
		background-color: $gray-ton;
		padding: 50px 0;
	}

	&-bottom {
		background-color: $dark-gray;
		padding: 30px 0;
	}

	&-info {
		h4 {
			color:$yellow-gold;
			text-transform: uppercase;
			font-size: 12px;

			&:after {
				display: none;
			}
		}

		p {
			color:$white;
			font-size: 14px;
		}

		ul {
			li {
				a {
					color:$white;
					font-size: 14px;
				}
			}
		}
	}

	&-subnav {
		// padding-top: 15px;
		ul {
			li {
				display: inline-block;
				vertical-align: middle;
				padding : 0 15px 0 0;
				a {
					color:$yellow-gold;
					text-transform: uppercase;
					font-size: 12px;
				}
			}
		}
	}

	&-btnbox {
		.btt {
			position: relative;
			font-size: 12px;
			font-weight: $semibold;
			color:$white;
			padding-left:20px;
			float: left;
			cursor: pointer;
			// margin-top:15px;
			&:hover {
				color:$yellow-gold;
			}
			&:before {
				content: '';
				top: 8px;
				left: 0;
				position:absolute;
				background-image: url('../../images/asrrow-top.png');
				background-repeat: no-repeat;
				background-position: 0 0px;
				width: 10px;
				height: 6px;

			}

			@include rwd(800) {
				float: right;
			}
		}
	}
}

.footer-logo {
	.footer-textlogo {
		a {
			font-family: 'Roboto', sans-serif;
			font-size: 37px;
			font-weight: 900;
			line-height: .8;
			color: $white;
		}
	}
}

// .blackStatic {
//     position: static;
//     background-color: black;
//     color: red;
//     transition: all 1s;
// }
// .redFixed {
//     position: fixed;
//     top: 80px;
//     // top: 80px;
//     background-color: red;
//     color: black;
//     transition: all 1s;
// }