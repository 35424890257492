.main-wrapper {
	width: 100%;
	background-color: $yellow-dark !important;
	padding-left: 20px;
	box-sizing: border-box;
}

.wrapper {
	background-color: $white;
	position:relative;
	overflow: hidden;

	@include lg {
		padding-top:208px;
	}
}

.container {
	@include md {
		width: 100%;
		padding:0 20px;
	}

	@include xxl {
		width: 100%;
		padding:0 50px;
	}


}

.o-boxtwo {

	&-left {
		float: left;
		background-color: $gray-ton;
		padding: 70px 0;
		margin-right: -15px;

	}

	&-right {
		float: right;
		width: 100%;
		background-color:$white;
		padding:30px 0 0 10px;

		@include md {
			padding:50px;
		}

		@include lg {
			padding:30px 0 0 10px;
		}


		@include xl {
			padding:70px 50px 0 30px;
		}

		&-inn {
			width: 100%;
			float: left;
			margin: 0 0 0 00px;

			@include xl {
				//width: 520px;
			}
		}
	}

	.content-box {
		width: auto;
		float: left;
		//margin:0 20px 0px 30px;
		padding:0 50px 30px 50px;
		color:$white;

		@include xl {
			width: 100%;
			float: right;
			padding:0 50px 30px 50px;
		}

		h2 {
			font-weight: $bold;
		}

		h4 {
			text-transform: uppercase;
		}

		ul {
			li {
				margin-bottom: 20px;
				padding-left:40px;
				position: relative;

				&:before {
					position:absolute;
					content: '';
					background-image: url('../../images/arrow.png');
					width: 22px;
					height: 10px;
					background-repeat: no-repeat;
					background-position:0 0;
					top: 30%;
					left: 0;
				}
				a {
					color:$white;
					border-bottom:1px solid $white;
				}
			}
		}

	}

	.news-content {
		width: 50%;
		float: left;
		padding-bottom:30px;
		padding-right:10px;

		span {
			color: $gray-mid-light;
			font-size:13px;
			margin-bottom:15px;
		}

		p {
			color:$gray-mid;
			font-size: 14px;
			font-weight: $medium;
		}
	}
}

.o-boxthree {
	
	overflow: hidden;
	@include lg {
		// max-height: 800px;
	}

	@include rwd(994) {
		max-height: 525px;
	}

	@include rwd(1200) {
		max-height: 100%;
	}

	@include rwd(1300) {
		// max-height: 770px;
	}
	@include rwd(1400) {
		// max-height: 800px;
	}
	&-left {

	}

	&-right {
		@include rwd(1000) {
				min-height: 600px;
			}
		img {
			// width: 680px;
			// height: 720px;
			// height: 100vh;
			object-fit: cover;
			width: 100%;
			height: 100%;



			@include rwd(680) {
				width: 100%;
			}

			@include rwd(978) {
				width: 100%;
				height: 100%;
			}

			@include rwd(994) {
				max-height: 520px;
			}

			@include rwd(1092) {
				max-height: 100%;
			}

			@include rwd(1200) {
				max-height: 100%;
			}

			@include rwd(1379) {
				width: 100%;
				height: 100%;
			}
		}
	}

	.whitebox {
		background-color:$white;
		// margin-right: -15px;
		float: left;
		width:100%;

		@include rwd(768) {
				// width:auto;
			}

		&-image {
			img {
				width: 100%;
			}
		}

		&-text {
			width: 100%;
			float: left;
			padding:20px 20px 30px 40px;
			text-align: center;

			@include md {
				text-align: left;
			}

			@include lg {
				width: 100%;
				float: right;
				padding:30px 20px 30px 50px;
			}

			@include xl {
				padding:50px 20px 30px 50px;
			}

			@include xxl {
				padding:80px 20px 30px 50px;
			}

			h3 {
				font-weight: $semibold;
				margin-bottom: 30px;
			}
		}
	}

	.purplebox {
		background-color:$purple;
		position: relative;
		width: 101%;
		//min-height: 100vh;
		clear: both;
		&-text {
			//background-color:$purple;
			text-align: center;
			color:$white;
			padding: 20px;
			width: 100%;
			clear: both;

			@include md {
				padding: 32px 40px 44px 20px;
			}

			@include lg {
				padding: 74px 25px;
			}

			@include rwd(978) {
				padding: 42px 25px;
			}

			@include xl {
				padding: 48px 25px;
			}

			@include xxl {
				padding: 109px 50px;
			}

			h3 {
				font-weight: $semibold;
				margin-bottom: 30px;
			}
		}

		&-image {
			width: 100%;
			height: auto;
			clear: both;
			@include rwd(768) {
				height: 305px;
    			overflow: hidden;
			}

			@include rwd(994) {
				height: auto;
			}

			@include rwd(850) {
				position: absolute;
				bottom: 0;
			}


			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				max-width: 100%;
			}
			
		}
		
	}
}

.o-quterbox {
	&-image {
		img {
			width: 100%;
		}
	}

	&-text {
		width: 100%;
		float: left;
		padding:20px;
		text-align: center;
		box-sizing: border-box;

		@include md {
			padding:0;
			text-align: left;
		}

		@include xl {
			padding-top: 55px;
			padding:55px 50px 20px 0px;
		}

		@include xxl {
			padding:103px 20px 20px 20px;
		}

		h3 {
			font-weight: $semibold;
			margin-bottom: 30px;
		}
	}
}

.displaybox {
	display: flex;
	flex-direction: row;
	justify-content: center;
	// height: 100vh;

	@include rwd(975) {
		height: auto;
	}

	@include rwd(1300) {
		max-height: 700px;
	}
	@include rwd(1800) {
		max-height: 800px;
	}

	@include rwd(1900) {
		max-height: 900px;
	}

	@include rwd(2200) {
		max-height: 1000px;
	}

	.purplebox-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		    align-items: center;
	}
}