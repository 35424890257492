// colors
$black: #000000;
$white: #ffffff;


$dark-gray: #060606;
$gray: #0d0d0d;
$gray-ton:#0e0d0e;

$semi-gray: #282627;
$semi-gray-light: #7b767a;

$gray-light: #f4eaf3;

$gray-mid: #16161d;

$gray-mid-light: #7e7e86;


$yellow-gold: #ffd23f;
$yellow-dark: #ecbf2d;

$purple: #531253;


// FONT SIZE 
// ---------
$large-font-size: 20px;
$base-font-size: 18px;
$small-font-size: 14px;


// FONT WEIGHT
// -----------
$thin: 100;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$balckbold: 900;
