.o-banner {
	background-image: url('../../images/banner.png');
	background-repeat: no-repeat;
	background-position: 0 0;
	min-height: 400px;
	background-size:cover;

	@include lg {
		min-height: 560px;
	}

	&-text {
		padding-top: 60px;
		color: $white;

		@include lg {
			padding-top: 180px;
		}

		p {
			color: $white;
		}
	}
}