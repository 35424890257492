.wrapper {
	&.contact-bg {
		background-image: url('../../images/contact-bg.png');
		background-repeat: no-repeat;
		background-position: 40% 0;

		@include xxl {
			background-position: 100% 0;
    		background-size: 103%;
		}

		.header {
			background-color:rgba(255,255,255,0);

			&.expandMe {
				background-color:rgba(255,255,255,1);
				&.expanded {
					background-color:rgba(255,255,255,0);
				}
			}

			
		}
	}
}

.o-contact {
	padding-bottom:90px;
	h2 {
		font-weight: $bold;
	}

	&-address {
		background-image: url('../../images/address-bg.png');
		background-repeat: no-repeat;
		background-position:0 0;
		background-size:cover;
		padding:50px;
		float: left;
		min-height: 490px;

		&-box {
			width: 100%;
			box-sizing: border-box;
			float: left;
			color: $white;
			padding-right:25px;

			@include rwd(992) {
				width: 50%;
			}

			h4 {
				text-transform: uppercase;
				font-weight: $semibold;
				letter-spacing: -0.5px;
				margin-bottom: 30px;

				&:after {
					right:auto;
					padding-right: 5px;
				}

				&:before {
					content: '';
					position:absolute;
					top: 32px;
					left: -50px;
					width: 80px;
					height: 5px;
					background-color:$yellow-dark;
				}
			}

			p {
				color: $white;
				font-weight: $medium;

				span {
					color: $yellow-dark;
					font-size: 23px;
					display: block;
					font-weight: bold;
				}
			}
			
		}
	}
}

.form-box {
	.textbox {
		padding: 20px;
		font-weight: $light;
		font-size: 16px;
		border:1px solid #e3e3e3;
		margin: 0 0 0px 0;
		width: 100%;
	}

	.selectbox {
		padding: 20px;
		font-weight: $light;
		font-size: 16px;
		border:1px solid #e3e3e3;
		margin: 0 0 0px 0;
		width: 100%;
		height: 66px;
	}

	.filechoose {
		font-weight: $light;
		font-size: 16px;
	}

	.col-md-6 {
		padding:7px;
	}
	.col-md-12 {
		padding:7px;
	}

	.btn {
		

		@include rwd(992) {
			float: right;
		}
	}

	h3 {
		font-weight: $semibold;
		color:$yellow-dark;
		font-size: 20px;
	}

	p {
		font-size: 16px;
	}
}