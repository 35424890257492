.bg-white {
	

	@include md {
		background-color: $white;
	}
}

.o-articalbox {
	width: 100%;
	float: left;

	&.news {
		.o-articalbox-right {}
	}

	&-inn {
		//background-color: $gray-ton;
		float: left;
		width: 100%;
	}
	p {
		font-size: 18px;
		line-height: 30px;
		color: $gray-mid-light;
		font-weight: $normal;
	}

	ul {
		li {
			font-size: 18px;
			line-height: 30px;
			color: $gray-mid-light;
			font-weight: $normal;
		}
	}

	&-left {
		background-color: $gray-ton;
		margin-right: -15px;
		max-width: 100%;
		

		 @include lg {
		 	min-height: 100vh;
		 	max-width: 600px;
		 }
	}

	&-right {
		padding:20px;
		width: 100%;
		box-sizing: border-box;
		//background-color: $white;
		clear: both;
		box-sizing:border-box;
		float: left;

		@include md {
		}

		@include lg {
			padding:40px 50px 45px 30px;
		}

		@include xl {
			
		}

		@include xxl {
			
		}
	}

	&-intro {
		clear: both;

		h1 {
			margin-bottom: 45px;
			margin-top: 0px;
			font-size: 35px;
			line-height: 50px;

			@include xl {
				font-size: 48px;
    			line-height: 60px;
			}
		}

		p {
			color: $yellow-dark;
			font-size: 26px;
			line-height: 36px;
			font-weight: $bold;
			margin-bottom: 40px;
		}
	}

	&-video {
		padding: 0 0 0 0;
		clear: both;
		video {
			width: 100%;
			margin-bottom: 45px;
		} 
	}

	&-data {
		display: block;
		clear: both;
		&-box {
			width: 100%;
			float: left;
			
			box-sizing:border-box;

			@include sm {
				width: 50%;
				padding-right: 30px;
			}

			&:last-child {
				@include sm {
					padding-right: 0px;
					padding-left:30px;
				}
			}
			span {
				font-weight: $bold;
				font-size: 40px;
				line-height: 50px;
				color:$purple;
				width: 100%;
				display: block;
				border-bottom:2px solid $purple;
				margin-bottom: 15px;

				@include sm {
					font-size: 90px;
					line-height: 100px;
				}
			}
		}
	}

	&-info {
		clear: both;
		display: block;

		h3 {
			font-weight: $bold;
		}
		ul {
			li {
				margin-bottom: 25px;
				position:relative;
				padding-left:30px;

				&:before {
					position:absolute;
					content: '';
					top: 12px;
					left: 0%;
					width: 7px;
					height: 7px;
					border-radius:100px;
					background-color:$yellow-gold;
				}
			}
		}

		.quote {
			h2 {
				font-size: 20px;
				line-height: 30px;
				font-weight: $bold;
				letter-spacing: -0.5px;
				margin:40px 0;
				position:relative;
				padding:20px;
					&:before {
						position:absolute;
						content: '';
						width:12px;
						height: 100%;
						background-color:$purple;
						top: 0;
						left: 0;
					}

				@include xl {
				    font-size: 32px;
				    line-height: 40px;
				    padding:30px 0 30px 95px;
  				}
			}
		}

		&-group {
			display: inline-block;
			width: 100%;
			margin-bottom: 20px;
		}

		&-box {
			width: 100%;
			display: inline-block;
			vertical-align: top;
			margin-bottom: 20px;
			box-sizing:border-box;

			@include lg {
				width: 49%;
				margin-bottom: 30px;
				padding-right:30px;
				min-height: 120px;
			}

			h2 {
				font-size: 25px;
				font-weight: bold;
				display: block;
				border-bottom:3px solid $purple;
				color:$gray-mid;

				@include xl {
					font-size: 32px;
				}
			}

			&-text {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				margin-top: 10px;

				span {
					font-size:48px;
					line-height: 50px;
					font-weight: bold;
					color: $purple;
					margin-right: 10px;
				}

				p {
					margin-bottom: 0px;
					display: inline-block;
					color: $gray-mid-light;

					b {
						color: $gray-mid-light;
						text-transform: capitalize;
					}
				}
			}

			
		}
	}

	&-summery {
		display: block;
		clear: both;
		&-box {
			display: block;
			clear: both;
			h3 {
				float: left;
				
				margin-top: 0px;
				font-size: 30px;
				font-weight: $bold;
				position:relative;
				display: block;
				width: 100%;

				@include lg {
					max-width: 45%;
				}
			}
			&-details {
				width: 100%;
				float: left;
				margin-bottom: 20px;

				@include lg {
					width: 55%;
					float: right;
					margin-bottom: 50px;
				}

				&-row {
					border-bottom:1px solid rgba(83,18,83,0.1);
					padding:20px 0;

					&:first-child {
						border-top:1px solid rgba(83,18,83,0.1);
					}



					span {
						width: 100%;
						float: left;

						@include xl {
							width: 180px;
						}
					}

					p {
						margin-bottom: 0px;
					}
				}
			}
		}
	}

	&-text {
		display: block;
		clear: both;
		border-bottom:1px solid rgba(83,18,83,0.1);
		padding-bottom:60px;
		margin-bottom: 60px;

		&:last-child {
			border-bottom:0px;
			padding-bottom:0px;
		}

		h3 {
			font-size: 30px;
			font-weight: $bold;
			position:relative;
		}

		&-box {
			width: 100%;
			float: left;
			margin-bottom: 20px;
			box-sizing:border-box;

			@include lg {
				width: 50%;
				margin-bottom: 45px;
				padding-right:30px;
				min-height: 220px;
			}


			h4 {
				font-size: 20px;
				color :$yellow-dark;
				font-weight: $bold;

				&:after{
					display: none;
				}
			}

			&-btn {
				clear: both;
			}
		}
	}

	&-search {
		h2 {
			font-weight: $bold;
		}

		h4 {
			text-transform: uppercase;
			font-weight: $bold;
		}

		.searchbox {
			padding: 20px 20px 20px 0;
			font-weight: $light;
			font-size: 16px;
			border-top:0px;
			border-right:0px;
			border-left:0px;
			border-bottom:1px solid #e3e3e3;
			margin: 0 0 0px 0;
			width: 80%;
		}

		.searchresualt {
			margin-top: 50px;
			h4 {
				text-transform: uppercase;
				font-weight: $bold;
			}

			&-box {
				padding:20px 0;
				border-bottom:1px solid #e3e3e3;

				h2 {
					font-size:16px;
					line-height: 20px;
					margin: 0;
					color:$purple;
					font-weight: $normal;
				}

				p {
					color :$gray-mid-light;
					font-size: 13px;
					margin-bottom: 0px;
				}
			}
		}
	}

	&-form {
		h2 {
			font-weight: $bold;
			margin-bottom: 30px;
			margin-top: 0px;
		}	
	}

	&-news {

		padding-bottom: 70px;

		h2 {
			margin-top: 0px;
		}

		.news-content {
			width: 100%;
			float: left;
			padding-bottom:20px;
			margin-bottom: 20px;
			border-bottom:1px solid #e3e3e3;

			&:nth-last-child(2) {
				border-bottom:none;
			}

			span {
				color: $gray-mid-light;
				font-size:13px;
				margin-bottom:15px;
			}

			p {
				color:$gray-mid;
				font-size: 14px;
				font-weight: $medium;
			}

			.btn {
				@include rwd(320) {
					margin-bottom: 30px;
				}

				@include rwd(320) {
					margin-bottom: 10px;
				}
			}
		}

		.btn-yellow {
			&:after {
				right:8%;
			}
		}
	}
}

.sidebox {

	// width: 100%;
	width: calc(100% - 0px);

	&.posStatic {
	    // position: static;
	    // transition: all 1s;
	}
	&.posFixed {
	    
	    // top: 80px;

	    @include md {
	    	position: fixed;
	    	top: 0px;
	    	left: 20px;
	    	width: calc(100% - 78.4%);
	    }

	    @include rwd(808) {
			width: calc(100% - 78.4%);
		}

		@include rwd(823) {
			width: calc(100% - 78.3%);
		}
		@include rwd(861) {
			width: calc(100% - 78.2%);
		}
		@include rwd(875) {
			width: calc(100% - 78.2%);
		}
		@include rwd(878) {
			width: calc(100% - 78.1%);
		}
		@include rwd(906) {
			width: calc(100% - 78%);
		}
		@include rwd(951) {
			width: calc(100% - 77.9%);
		}
		@include rwd(955) {
			width: calc(100% - 77.9%);
		}

		@include rwd(959) {
			width: calc(100% - 77.8%);
		}

		@include rwd(967) {
			width: calc(100% - 77.8%);
		}

		@include rwd(970) {
			top: 0px;
			width: calc(100% - 77.8%);
		}

		@include rwd(975) {
			top: 0px;
			width: calc(100% - 77.8%);
		}
		@include rwd(976) {
			top: 0px;
			width: calc(100% - 77.8%);
		}
		@include rwd(977) {
			top: 0px;
			width: calc(100% - 77.8%);
		}
		@include rwd(990) {
			top: 0px;
			width: calc(100% - 77.8%);
		}
		@include rwd(992) {
			top: 80px;
			width: calc(100% - 75.8%);
		}
		@include rwd(1463) {
			top: 80px;
			width: calc(100% - 75.8%);
		}
		@include rwd(1688) {
			top: 80px;
			width: calc(100% - 75.7%);
		}
		@include rwd(1888) {
			top: 80px;
			width: calc(100% - 75.6%);
		}
		@include rwd(2279) {
			top: 80px;
			width: calc(100% - 80.2%);
		}

	    //  @include lg {
	    // 	position: fixed;
	    // 	top: 80px;
	    // 	// max-width: 24.5%;
	    // 	width: calc(100% - 781px);
	    // }
	}
}

.sidebar{
	width: calc(100% - 0px);
	background-color:$yellow-dark;
	float: left;
	padding: 20px;
	transition: all 1s;
	 @include lg {
	 	padding: 45px 0;
	 }

	

	&-inn {
		width: 100%;
		float: right;
		


		@include lg {
			padding-left:20px;
		}

		@include xl {
			padding-left:50px;
		}

		@include xxl {
			padding-left:50px;
		}

		h4 {
			font-size: 16px;
			color:#170312;
			text-transform: uppercase;
			&:after {
				display: none;
			}
		}

		p {
			font-size: 16px;
			color:#170312;
			line-height: 24px;
			margin-bottom: 5px;
			font-weight: $normal;
		}
	}

	ul {
		li {
			margin-bottom: 15px;
			a {
				font-size: 16px;
				line-height: 20px;
				color: $gray-mid;
				font-weight: $medium;

				&:hover {
					border-bottom:1px solid $gray-mid;
				}
			}
		}
	}
}

.invlogin-area {
	width: 100%;
	background-color:$semi-gray;
	float: left;
	padding: 15px 20px;

	@include lg {
		padding: 15px 0;
	}

	&-inn {
		width: 100%;
		float: right;
		

		@include lg {
			padding-left: 50px;
		}

		@include xl {
			padding-left: 50px;
		}

		@include xxl {
			padding-left: 50px;
		}
	}

	h4 {
		font-size: 16px;
		color:$white;
		text-transform: uppercase;
		&:after {
			display: none;
		}
	}

	p {
		font-size: 22px;
		color:$yellow-dark;
		line-height: 30px;
		margin-bottom: 5px;
		font-weight: $medium;
	}

	ul {

		li {
			margin-bottom: 0px;
			position:relative;
			&:after {
				position:absolute;
				content: '';
				top: 52%;
				right: auto;
				width: 5px;
				height: 5px;
				border-radius:100px;
				background-color:$yellow-gold;
			}
			a {
				font-size: 16px;
				color: $white;
				font-weight: $bold;
				text-transform: uppercase;
				padding-right:5px;

				&:hover {
					color:$yellow-dark;
				}
			}
		}
	}
}

.o-news{

	&-related {
		float: left;
		width: 100%;

		h4 {
			text-transform: uppercase;
			font-weight: $semibold;
		}

		.btn-yellow {
			&:after {
				right:8%;
			}
		}
	}

	&-image {
		margin-bottom: 20px;
		img {
			max-width: 100%;
			width: 100%;
		}
	}

	&-text {
		margin-bottom: 50px;
		span {
			color:$gray-mid-light;
			font-size: 13px;
			margin-bottom: 15px;
		}

		h2 {
			font-size: 21px;
			line-height: 28px;
			font-weight: $bold;
			color:$gray-mid;
		}
	}
	
}

.vtwoartical {

	p {
		color:$gray-mid-light;
	}
	.o-articalbox-intro {
		margin-bottom: 45px;
		h1 {
			margin-bottom: 25px;
		}
		p {
			margin-bottom: 25px;
			color: $yellow-dark;
		}
	}

	.o-articalbox-info {
		h3 {
			font-size: 38px;	
			line-height: 48px;
			margin-bottom: 28px;
		}
	}

	.o-articalbox-text {
		h2 {
			font-size: 48px;
			line-height: 50px;
			font-weight: bold;
			color: $gray-mid;
			margin-right: 10px;
		}

		h3 {
			color:$purple;
			font-size: 38px;	
			line-height: 48px;
			margin-bottom: 28px;
			font-weight: $medium;
		}
	}
}